<i18n lang="json">
{
  "pt": {
    "login-headline": "Digite seu e-mail para continuar",
    "login-description": "Enviaremos um link para autenticação.",

    "email-input-label": "E-mail",
    "email-input-placeholder": "Digite seu e-mail",

    "login-button": "Continuar",

    "login-success-headline": "Pronto! {0} Agora clique no link que enviamos para o e-mail que você informou.",
    "login-success-description": "Enviado para {email}",

    "resend-link": "Reenviar",
    "resend-link-seconds": "Reenviar em {seconds}s",

    "agreement-acknowledgement": "Ao continuar você confirma que {0} está ciente de nossos {1}",
    "tos-privacy": "Termos de Uso e Privacidade",

    "error-email-not-found": "E-mail não encontrado.",
    "error-email-invalid": "Forneça um e-mail válido."
  },
  "en": {
    "login-headline": "Enter your email to continue",
    "login-description": "We will send an authentication link.",

    "email-input-label": "Email",
    "email-input-placeholder": "Enter your email",

    "login-button": "Continue",

    "login-success-headline": "Done! {0} Now click on the link we sent to the email you provided.",
    "login-success-description": "Sent to {email}",

    "resend-link": "Resend",
    "resend-link-seconds": "Resend in {seconds}s",

    "agreement-acknowledgement": "By continuing, you confirm that you are {0} aware of our {1}",
    "tos-privacy": "Terms of Use and Privacy Policy",

    "error-email-not-found": "Email not found.",
    "error-email-invalid": "Provide a valid email."
  }
}
</i18n>

<script lang="ts" setup>
import { logError } from "~/utils/logging";
import { BaseInput, Button } from "@aprenderdesign/components";
import isEmail from "validator/lib/isEmail";

definePageMeta({
  layout: "blank",
});

const { user } = await useAuth();

if (user.value) {
  await navigateTo("/");
}

const { t, locale } = useI18n();

const form = reactive({
  email: "",
  register: "",
  error: null as string | null,
  validated: false,
  exists: false,
  readyToResend: false,
  secondsToResend: 0,
  verifying: false,
});

watch(
  () => form.email,
  () => {
    form.error = null;
  }
);

async function sendMagicLink() {
  form.error = null;

  if (!validateEmail()) {
    return;
  }

  try {
    form.verifying = true;

    await $fetch("/api/magic-link", {
      method: "POST",
      body: {
        email: form.email,
        locale: locale.value,
      },
    });

    form.exists = true;
    form.validated = true;

    form.readyToResend = false;
    form.secondsToResend = 60;

    const interval = setInterval(() => {
      if (form.secondsToResend > 1) {
        form.secondsToResend -= 1;
      } else {
        form.readyToResend = true;
        clearInterval(interval);
      }
    }, 1000);
  } catch (err: any) {
    const response = err.data;

    logError(err);

    form.exists = false;

    if (response?.data?.message?.match(/email not found/i)) {
      form.error = t("error-email-not-found");
    } else {
      form.error = response?.data?.message ?? err?.statusMessage ?? err.message;
    }

    form.validated = false;
  } finally {
    form.verifying = false;
  }
}

function validateEmail() {
  if (!isEmail(form.email)) {
    form.error = t("error-email-invalid");

    return false;
  }

  return true;
}
</script>

<template>
  <main
    class="flex w-full flex-col overflow-auto lg:grid lg:h-screen lg:grid-cols-2"
  >
    <div
      class="flex min-h-[80vh] w-full flex-col justify-between px-5 py-5 sm:py-20 sm:px-20 lg:h-full xl:pl-36 xl:pr-5"
    >
      <h1 class="sr-only">Login</h1>
      <h2
        class="inline-flex items-center gap-2 text-lg font-medium tracking-tight sm:mt-5"
      >
        <svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <circle cx="15.5" cy="15" r="15" fill="black" />
        </svg>

        Aprender Design
      </h2>

      <form
        v-if="!form.validated"
        class="my-14 lg:my-0"
        @submit.prevent="sendMagicLink"
      >
        <h3 class="mb-2 text-xl font-medium md:text-2xl">
          {{ t("login-headline") }}
        </h3>
        <p class="text-base opacity-50">
          {{ t("login-description") }}
        </p>

        <BaseInput
          v-model:value="form.email"
          name="email"
          :label="t('email-input-label')"
          :placeholder="t('email-input-placeholder')"
          class="my-8 sm:max-w-md"
          :error="form.error"
        />

        <Button
          large
          theme="dark"
          :disabled="form.verifying || !form.email"
          class="mt-1 font-bold"
        >
          {{ t("login-button") }}
        </Button>
      </form>

      <form v-else-if="form.exists" class="my-14 lg:my-0" @submit.prevent>
        <i18n-t
          keypath="login-success-headline"
          tag="h3"
          class="mb-2 max-w-sm text-xl font-medium md:text-2xl"
        >
          <br />
        </i18n-t>
        <p class="text-base opacity-50">
          {{ t("login-success-description", { email: form.email }) }}
        </p>

        <Button
          large
          theme="dark"
          :disabled="!form.readyToResend"
          class="mt-8 font-bold"
          @click="sendMagicLink"
        >
          <template v-if="!form.readyToResend">{{
            t("resend-link-seconds", { seconds: form.secondsToResend })
          }}</template>
          <template v-else>{{ t("resend-link") }}</template>
        </Button>
      </form>

      <div>
        <i18n-t
          keypath="agreement-acknowledgement"
          tag="p"
          class="mb-4 max-w-xs text-xs text-gray-4"
        >
          <br />

          <a href="#" class="font-medium text-neutral-1">
            {{ t("tos-privacy") }}
          </a>
        </i18n-t>

        <p class="text-xs">
          <a
            :href="`mailto:${t('contact-email')}`"
            class="font-medium text-neutral-1"
          >
            {{ t("contact-email") }}
          </a>
        </p>
      </div>
    </div>
    <div
      class="sticky top-0 h-screen w-full bg-[url(/img/bg3.png)] bg-cover bg-center bg-no-repeat"
    ></div>
  </main>
</template>
